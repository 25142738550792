import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ZohoChat = () => {
  const location = useLocation();

  useEffect(() => {
    const hideZohoChat = () => {
      const chatElement = document.getElementById("zsiq_float"); // Default Zoho chat widget ID
      if (chatElement) {
        chatElement.style.display = "none";
      }
    };

    const showZohoChat = () => {
      const chatElement = document.getElementById("zsiq_float"); // Default Zoho chat widget ID
      if (chatElement) {
        chatElement.style.display = "block";
      }
    };

    // Check route and apply logic
    if (location.pathname?.split("/")?.[1] === "onboarding") {
      hideZohoChat();
    } else {
      showZohoChat();
    }
  }, [location.pathname]);

  return null; // No UI, just logic
};

export default ZohoChat;
