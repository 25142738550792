import React from "react";
import Select from "react-select";
import { useField } from "formik";

interface DropdownProps {
    label?: string;
    required?: boolean;
    options: { value: string; label: string }[]; // react-select options
    name: string;
    placeHolder: string;
    backgroundColor?: string;
    isMulti?: boolean; // To enable multi-selection
    labelClassNames?: string;
    customHeight?: string; // New prop for custom height
}

const Dropdown: React.FC<DropdownProps> = ({
    label,
    options,
    name,
    placeHolder,
    required,
    backgroundColor,
    isMulti = false,
    labelClassNames,
    customHeight = "37px", // Default height
}) => {
    const [field, meta, helpers] = useField(name); // Formik's useField hook
    const { setValue } = helpers;

    return (
        <div className="flex flex-col gap-1 w-full">
            {!!label && (
                <label className={`block text-white text-xs ${labelClassNames || ""}`} style={{ color: "white" }}>
                    {label} {!!required && <span className="text-red-700">*</span>}
                </label>
            )}
            <div
                style={{ backgroundColor: backgroundColor || "white" }}
                className="w-full border border-solid border-black-900 rounded-lg"
            >
                <Select
                    menuPlacement={"top"}
                    options={options}
                    isMulti={isMulti} // Enable multi-selection
                    onChange={(selectedOptions: any) =>
                        setValue(
                            isMulti
                                ? selectedOptions?.map((option) => option.value) // Array of selected values
                                : selectedOptions?.value || "" // Single value
                        )
                    }
                    placeholder={placeHolder}
                    styles={{
                        control: (base) => ({
                            ...base,
                            backgroundColor: backgroundColor || "white",
                            borderRadius: "0.5rem", // Reduced border radius
                            height: customHeight, // Customizable height
                            minHeight: customHeight, // Ensure minimum height matches customHeight
                            border: "0px", // Removed border
                            padding: "0 8px", // Reduced padding
                        }),
                        placeholder: (base) => ({
                            ...base,
                            color: "#a1a1a1", // Lighter placeholder text color
                            fontSize: "0.875rem", // Smaller font size
                            textOverflow: "ellipsis", // Prevents overflow in long text
                            whiteSpace: "nowrap", // Ensures placeholder text doesn't break
                            overflow: "hidden", // Hides overflow text
                        }),
                        menu: (base) => ({
                            ...base,
                            maxHeight: "400px", // Prevent overflow of dropdown menu
                            overflowY: "auto", // Scroll if there are too many options
                            scrollbarWidth: "thin",
                            msOverflowStyle: "auto",
                            "::-webkit-scrollbar": {
                                width: "6px",
                                height: "6px",
                            },
                            "::-webkit-scrollbar-thumb": {
                                background: "#c1c1c1",
                                borderRadius: "10px",
                            },
                            "::-webkit-scrollbar-track": {
                                background: "#f1f1f1",
                                borderRadius: "10px",
                            },
                        }),
                        multiValue: (base) => ({
                            ...base,
                            margin: "0 2px", // Compact spacing for multi-select values
                            padding: "0 4px", // Compact padding for selected items
                        }),
                    }}
                    value={
                        isMulti
                            ? options.filter((option) => field?.value?.includes(option.value)) // Sync multi-select values
                            : options.find((option) => option.value === field.value) // Sync single value
                    }
                    isClearable // Optional: Allows clearing the selection
                />
                {meta.touched && meta.error && (
                    <div className="text-red-600 text-xs mt-1">{meta.error}</div>
                )}
            </div>
        </div>
    );
};

export default Dropdown;
