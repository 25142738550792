import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import { Button } from "components";
import ImageUpload from "components/ImageUpload";
import FormWrapper from '../component/FormWrapper';

const SUBJECT_OPTIONS = [
    { value: "Telugu", label: "Telugu" },
    { value: "Math", label: "Math" },
    { value: "English", label: "English" },
    { value: "Science", label: "Science" },
    { value: "Social", label: "Social" },
    { value: "Hindi", label: "Hindi" },
    { value: "Others", label: "Others" },
];

const QUALIFICATION_OPTIONS = [
    { value: "B.Ed", label: "B.Ed" },
    { value: "M.Ed", label: "M.Ed" },
    { value: "Ph.D", label: "Ph.D" },
    { value: "M.Phil", label: "M.Phil" },
    { value: "B.Sc", label: "B.Sc" },
    { value: "M.Sc", label: "M.Sc" },
    { value: "B.A", label: "B.A" },
    { value: "M.A", label: "M.A" },
    { value: "B.Tech", label: "B.Tech" },
    { value: "M.Tech", label: "M.Tech" },
    { value: "Others", label: "Others" },
];

const Faculties = () => {
    const maxForms = 5;
    const emptyFacultyData = {
        FACULTY_NAME: "",
        FACULTY_SUBJECT: "",
        FACULTY_QUALIFICATION: "",
        FACULTY_EXPERIENCE: "",
        FACULTY_DESCRIPTION: "",
        FACULTY_IMAGE: "",
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(localStorage.getItem("faculties")) || [];
        return savedData.length ? savedData : [emptyFacultyData];
    };

    return (
        <FormWrapper
            formHeading={<div className="flex flex-col gap-4 justify-center items-center">
                <p className="text-4xl">Empowering the Leaders of Tomorrow</p>
                <div className="mx-12 w-[50%] text-center">
                    <p className="text-xl font-thin">
                        Upload your teacher's photos and showcase the inspiring faces behind the learning.
                        Highlight the educators shaping your student's future!</p>
                </div>
                <p className="text-[1.2vw] italic mt-1 text-gray-500 font-thin">
                    (Maximum 5 Faculty Details)
                </p>
            </div>}
            childern={<Formik
                initialValues={{ faculties: loadInitialData() }}
                onSubmit={(values) => {
                    localStorage.setItem("faculties", JSON.stringify(values.faculties));
                }}
            >
                {({ values, handleSubmit, resetForm }) => (
                    <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <div className="flex flex-col justify-center items-center gap-4 bg-slate-50 rounded-3xl my-8 mx-12 p-12">
                            <div className="w-full max-w-5xl">
                                <FieldArray name="faculties">
                                    {({ push, remove }) => (
                                        <>
                                            {values.faculties.map((faculty, index) => (
                                                <div
                                                    key={index}
                                                    className="bg-white rounded-3xl p-14 w-full max-w-5xl z-1000 mb-4"
                                                >
                                                    <div
                                                        className="relative flex flex-col gap-6 bg-white rounded-3xl p-10"
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        {values.faculties.length > 1 && (
                                                            <button
                                                                type="button"
                                                                className="absolute top-4 right-4 bg-slate-200 text-slate-700 font-bold text-[1.8vw] rounded-full w-10 h-10 flex items-center justify-center hover:bg-slate-300"
                                                                onClick={() => remove(index)}
                                                            >
                                                                -
                                                            </button>
                                                        )}

                                                        <div className="flex justify-center w-full">
                                                            <div className="w-[40%]">
                                                                <Field name={`faculties.${index}.FACULTY_IMAGE`}>
                                                                    {({ field, form }) => (
                                                                        <ImageUpload
                                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                            imageValue={field.value}
                                                                            onChange={(item) =>
                                                                                form.setFieldValue(`faculties.${index}.FACULTY_IMAGE`, item)
                                                                            }
                                                                            wantHDOption
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>

                                                        <Input
                                                            label="What's Their Name?"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`faculties.${index}.FACULTY_NAME`}
                                                            type="text"
                                                            placeHolder="Enter their name here"
                                                            required
                                                        />

                                                        <Dropdown
                                                            label="What Subject Do They Teach?"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`faculties.${index}.FACULTY_SUBJECT`}
                                                            placeHolder="Click here (e.g., Telugu, Math, English, etc)"
                                                            options={SUBJECT_OPTIONS}
                                                            required
                                                            isMulti
                                                        />

                                                        <Dropdown
                                                            label="Their Qualification"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`faculties.${index}.FACULTY_QUALIFICATION`}
                                                            placeHolder="Click here (e.g., B.Ed, M.Ed, etc)"
                                                            options={QUALIFICATION_OPTIONS}
                                                            required
                                                        />

                                                        <Input
                                                            label="What is their Years of Experience in Teaching?"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`faculties.${index}.FACULTY_EXPERIENCE`}
                                                            type="number"
                                                            placeHolder="Enter number of years (e.g., 15 Years)"
                                                            required
                                                        />

                                                        <Input
                                                            label="A Few Words About Them!"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`faculties.${index}.FACULTY_DESCRIPTION`}
                                                            type="text"
                                                            placeHolder="Optional"
                                                        />
                                                    </div>
                                                </div>
                                            ))}

                                            {values.faculties.length < maxForms && (
                                                <div className="flex justify-center mt-4">
                                                    <button
                                                        type="button"
                                                        onClick={() => push(emptyFacultyData)}
                                                        className="bg-slate-400 text-slate-700 font-bold text-[1.8vw] rounded-full w-14 h-14 flex items-center justify-center hover:bg-slate-300"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </div>
                        <div className="flex justify-end gap-4 p-8 mx-6 mb-6">
                            <button
                                type="button"
                                className="bg-slate-600 text-[white] py-2 px-4 rounded-lg"
                                onClick={() => resetForm()}
                            >
                                RESET
                            </button>
                            <button className="bg-sky-600 text-[white] py-2 px-4 rounded-lg">
                                PROCEED
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>}
        />
    );
};

export default Faculties;