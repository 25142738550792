import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import './GoogleMaps.css';

export interface Location {
  lat: number;
  lng: number;
  address: string | null;
}

interface GoogleMapsProps {
  onLocationClick: (location: Location) => void;
  selectedLocation: Location | null;
  initialLocation: Location | null;
}

export const GoogleMaps: React.FC<GoogleMapsProps> = ({
  onLocationClick,
  selectedLocation,
  initialLocation,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
  });

  const [currentLocation, setCurrentLocation] = useState<Location | null>(
    initialLocation,
  );
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const formattedAddress = results[0].formatted_address;
          const clickedLocation: Location = {
            lat,
            lng,
            address: formattedAddress,
          };
          onLocationClick(clickedLocation);

          setCurrentLocation(clickedLocation);

          if (marker) {
            marker.setPosition({ lat, lng });
          } else {
            const newMarker = new window.google.maps.Marker({
              position: { lat, lng },
              title: formattedAddress,
            });
            setMarker(newMarker);
          }
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
    } else {
      console.error('e.latLng is null');
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          const initialLocation: Location = {
            lat: latitude,
            lng: longitude,
            address: null,
          };
          onLocationClick(initialLocation);
          setCurrentLocation(initialLocation);
        },
        error => {
          console.error('Error getting current location:', error);
        },
      );
    } else {
      console.error('Geolocation is not supported in this browser.');
    }
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      if (marker) {
        const { lat, lng } = selectedLocation;
        marker.setPosition({ lat, lng });
      }
    }
  }, [selectedLocation, marker]);

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <div className="googleMapContinerStyle">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="googleMapContainerStyleHandler"
          center={
            selectedLocation
              ? selectedLocation
              : currentLocation
              ? currentLocation
              : { lat: 18.52043, lng: 73.856743 }
          }
          zoom={currentLocation ? 16 : 6}
          onClick={handleMapClick}
        >
          {selectedLocation ? (
            <Marker
              position={{
                lat: selectedLocation.lat,
                lng: selectedLocation.lng,
              }}
              title={selectedLocation.address || ''}
              onLoad={marker => setMarker(marker)}
            />
          ) : (
            currentLocation && (
              <Marker
                position={{
                  lat: currentLocation.lat,
                  lng: currentLocation.lng,
                }}
                title={currentLocation.address || ''}
                onLoad={marker => setMarker(marker)}
              />
            )
          )}
        </GoogleMap>
      )}
    </div>
  );
};
