import React from "react";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import LandingForm from "pages/Onboarding/LandingForm";
import Faculties from "pages/Onboarding/Faculties";
import Infrastructure from "pages/Onboarding/Infrastructure";
import Recognition from "pages/Onboarding/Recognition";
import Testimonials from "pages/Onboarding/Testimonials";
import Leaders from "pages/Onboarding/Leaders";
import Brochures from "pages/Onboarding/Brochures";
import Teachers from "pages/Onboarding/Teachers";

const SIDEBAR = [
    { label: "Your Website", route: "/website" },
    { label: "School Info", route: "/onboarding" },
    { label: "Founder's / Leader's Info", route: "/onboarding/leaders" },
    { label: "Facultie's / Teacher's Info", route: "/onboarding/teachers" },
    { label: "Recognitions", route: "/onboarding/recognition" },
    { label: "Campus Info", route: "/onboarding/campusinfo" },
    { label: "Chatbot", route: "/onboarding/chatbot" },
    { label: "Testimonials", route: "/onboarding/testimonials" },
    { label: "Branding Materials", route: "/onboarding/brochure" },
]

const SF2 = () => {
    // const location = useLocation();
    return (
        <div className="container mx-auto font-poppins text-gray-ob sm:px-4 lg:px-8">
            <nav className="flex w-full justify-between items-center bg-slate-100 p-1 sm:p-4">
                <div className="flex w-full items-center gap-2 sm:gap-4">
                    <NavLink to="/" style={{ width: '3.5vw' }}>
                        <img
                            className="w-[14vw] sm:w-[6vw] lg:w-[3vw] h-auto object-contain mx-auto"
                            src={"/images/Logo.svg"}
                            alt="SooMuch Logo"
                            loading={"lazy"}
                        />
                    </NavLink>
                    <p className="font-bold text-sm sm:text-lg md:text-xl">Onboarding Setup</p>
                </div>
            </nav>
            <div className="grid grid-cols-12 gap-x-4 gap-y-2 p-2">
                {/* Stepper Wrapper - Hidden on mobile and tablet, visible on larger screens */}
                <div className="col-span-12 sm:hidden md:hidden laptop:block"> {/* Hidden on mobile and tablet */}
                    <div className="stepperWrapperOB">
                        {SIDEBAR?.slice(1)?.map((item, index) => (
                            <div key={"akjdilqwejhkdhasdasd" + index} className={`stepperItemOB completedOB activeOB`}>
                                <div className="stepCounterOB">
                                    <img
                                        className="w-5 h-5 object-contain mx-auto"
                                        src={"/images/WhiteTick.svg"}
                                        alt="SooMuch Logo"
                                        loading={"lazy"}
                                    />
                                </div>
                                <div className="text-xs text-center w-[70%]">{item?.label}</div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Sidebar - Hidden on mobile and tablet, visible on larger screens */}
                {/* <div className="col-span-2 border border-gray-400 rounded-3xl overflow-auto p-4 sm:hidden md:hidden laptop:block"> 
                    <div className="flex flex-col gap-1">
                        {SIDEBAR?.map((item, index) => (
                            <NavLink end={item?.route === "/onboarding"} to={item?.route} key={"asdkajsdhwwnebsandasdasdwqeasd" + index} className={({ isActive }) => `border border-gray-400 py-2 px-4 text-center font-semibold text-sm ${isActive ? "gradientBGOB text-[white]" : "gradientTextOB"}`}>
                                {item?.label}
                            </NavLink>
                        ))}
                    </div>
                </div> */}

                {/* Main Content Area */}
                <div className="col-span-12 sm:col-span-12 md:col-span-12 rounded-3xl">
                    <Routes>
                        {/* <Route path="/onboarding" element={<LandingForm />} />                    //screen1 */}
                        <Route
                            index
                            element={<LandingForm />}
                        />
                        <Route path="/leaders" element={<Leaders />} />                //screen2
                        <Route path="/infrastructure" element={<Infrastructure />} />  //screen4
                        <Route path="/faculties" element={<Faculties />} />            //screen5
                        <Route path="/recognition" element={<Recognition />} />        //screen6
                        <Route path="/testimonials" element={<Testimonials />} />      //screen7
                        <Route path="/brochure" element={<Brochures />} />             //screen9
                        <Route path="/teachers" element={<Teachers />} />             //screen10
                    </Routes>
                </div>
            </div>

        </div>
    );
};

export default SF2;
