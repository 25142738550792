import React from "react";
import { Link } from "react-router-dom";

const SIDEBAR = [
    { label: "Your Website" },
    { label: "School Info" },
    { label: "Founder's / Leader's Info" },
    { label: "Facultie's / Teacher's Info" },
    { label: "Recognitions" },
    { label: "Campus Info" },
    { label: "Chatbot" },
    { label: "Testimonials" },
    { label: "Branding Materials" },
]

const FormWrapper: ({ title, childern, formHeading }: {
    title?: any;
    childern: any;
    formHeading: any;
}) => React.JSX.Element = ({ title, childern, formHeading }) => {
    return (
        <div>
            {!!title && (
                <div className="bg-gray-ob-fw-card-title-bg flex flex-col justify-center items-center gap-2 p-2 sm:gap-4 sm:p-6 md:p-8">
                    {title}
                </div>
            )}
            <div className="rounded-3xl sm:my-2 sm:mx-1 sm:p-2 md:p-8">
                {!!formHeading && (
                    <div className="text-center text-sm sm:text-2xl font-semibold mb-2 sm:mb-4">{formHeading}</div>
                )}
                {childern}
            </div>
            {/* <div className="rounded-3xl sm:my-2 sm:mx-1 sm:p-2 md:p-8">
                {!!formHeading && (
                    <div className="text-center text-sm sm:text-2xl font-semibold mb-2 sm:mb-4">{formHeading}</div>
                )}
                {childern}
            </div> */}
        </div>
    );
};

export default FormWrapper;
