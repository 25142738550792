import React from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import TextArea from "components/TextArea";


const SUBJECT_OPTIONS = [
    { value: "Telugu", label: "Telugu" },
    { value: "Math", label: "Math" },
    { value: "English", label: "English" },
    { value: "Science", label: "Science" },
    { value: "Social", label: "Social" },
    { value: "Hindi", label: "Hindi" },
    { value: "Others", label: "Others" },
];

const QUALIFICATIONS_OPTIONS = [
    { value: "B.Ed", label: "B.Ed" },
    { value: "M.Ed", label: "M.Ed" },
    { value: "Ph.D", label: "Ph.D" },
    { value: "M.Phil", label: "M.Phil" },
    { value: "B.Sc", label: "B.Sc" },
    { value: "M.Sc", label: "M.Sc" },
    { value: "B.A", label: "B.A" },
    { value: "M.A", label: "M.A" },
    { value: "B.Tech", label: "B.Tech" },
    { value: "M.Tech", label: "M.Tech" },
    { value: "Others", label: "Others" },
];


const Teachers = () => {

    const maxForms = 5;
    const emptyTeachersData = {
        TEACHER_IMAGE: "",
        TEACHER_NAME: "",
        TEACHER_SUBJECT: "",
        TEACHER_QUALIFICATION: "",
        TEACHER_EXPERIENCE: "",
        TEACHER_DESCRIPTION: "",
    }

    const initialValues = () => {
        const savedData = JSON.parse(localStorage.getItem("teachers")) || [];
        return savedData.length ? savedData : [emptyTeachersData];
    };

    return (
        <FormWrapper
            childern={
                <Formik
                    initialValues={{ teachers: initialValues() }}
                    onSubmit={(values) => {
                        localStorage.setItem("teachers", JSON.stringify(values.teachers));
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form
                            onSubmit={handleSubmit}
                            className="flex flex-col gap-4"
                        >
                            <div
                                className="bg-onboardingBackground flex flex-col justify-center items-center gap-4 rounded-3xl sm:mx-2 p-6 sm:p-4"
                                
                            >
                                <div className="w-full max-w-4xl sm:max-w-full">
                                    <FieldArray name="teachers">
                                        {({ push, remove }) => (
                                            <>
                                                <div className="flex flex-col gap-4 justify-center items-center" style={{ color: 'white' }}>
                                                    <p className="text-2xl sm:text-xl leading-relaxed text-center font-bold text-white">
                                                        Let's Show Our Heroes!
                                                    </p>
                                                    <div className="mx-4 sm:mx-2 w-full sm:w-[90%] text-center leading-relaxed mb-6">
                                                        <p className="text-base sm:text-sm font-thin text-white">
                                                            Upload your teachers' photos and showcase the inspiring faces behind the learning. Highlight the educators shaping your students' future!
                                                        </p>
                                                        <p className="text-xs italic mt-2 text-gray-300">
                                                            (Maximum 5 Faculty Details)
                                                        </p>
                                                    </div>
                                                </div>
                                                {values.teachers.map((teachersElement, index) => (
                                                    <div
                                                        key={index}
                                                        className="bg-transparent rounded-3xl p-6 sm:p-4 w-full max-w-4xl sm:max-w-full mb-4"
                                                    >
                                                        <div
                                                            className="relative flex flex-col gap-4 bg-transparent rounded-3xl p-6 sm:p-4"
                                                            style={{
                                                                background: 'rgba(255, 255, 255, 0.2)',
                                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                backdropFilter: 'blur(10px)',
                                                                borderRadius: '30px',
                                                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                                            }}
                                                        >
                                                            {values.teachers.length > 1 && (
                                                                <button
                                                                    type="button"
                                                                    className="absolute top-2 right-2 bg-white text-slate-700 font-bold text-base rounded-full w-8 h-8 flex items-center justify-center"
                                                                    style={{
                                                                        color: 'rgba(48, 38, 38, 0.9)',
                                                                        backgroundColor: 'white',
                                                                    }}
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    x
                                                                </button>
                                                            )}
                                                            <div className="flex justify-center">
                                                                <div className="w-[60%] sm:w-[80%]">
                                                                    <Field name={`teachers.${index}.TEACHER_IMAGE`}>
                                                                        {({ field, form }) => (
                                                                            <ImageUpload
                                                                                imageValue={field.value}
                                                                                onChange={(item) =>
                                                                                    form.setFieldValue(`teachers.${index}.TEACHER_IMAGE`, item)
                                                                                }
                                                                                wantHDOption
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <Input
                                                                label="What's Their Name?"
                                                                labelClassNames="text-center font-bold text-base sm:text-sm pb-3"
                                                                name={`teachers.${index}.TEACHER_NAME`}
                                                                type="text"
                                                                placeHolder="Enter Their Name Here"
                                                            />
                                                            <Dropdown
                                                                label="What Subject Do They Teach?"
                                                                labelClassNames="text-center font-bold text-base sm:text-sm pb-3"
                                                                name={`teachers.${index}.TEACHER_SUBJECT`}
                                                                placeHolder="Select Subject (e.g., Math, Science, etc.)"
                                                                options={SUBJECT_OPTIONS}
                                                                required
                                                            />
                                                            <Dropdown
                                                                label="Their Qualification"
                                                                labelClassNames="text-center font-bold text-base sm:text-sm pb-3"
                                                                name={`teachers.${index}.TEACHER_QUALIFICATION`}
                                                                placeHolder="Enter Qualification Here (e.g., B.Ed., M.Ed., etc.)"
                                                                options={QUALIFICATIONS_OPTIONS}
                                                                required
                                                            />
                                                            <Input
                                                                label="How Many Years of Experience Do They Have?"
                                                                labelClassNames="text-center font-bold text-base sm:text-sm pb-3"
                                                                name={`teachers.${index}.TEACHER_EXPERIENCE`}
                                                                type="text"
                                                                placeHolder="Enter no. of years (e.g., 15 years)"
                                                            />
                                                            <TextArea
                                                                label="A Few Words About Them!"
                                                                labelClassNames="text-center font-bold text-base sm:text-sm pb-3"
                                                                name={`teachers.${index}.TEACHER_DESCRIPTION`}
                                                                placeHolder="Introducing the heroes of our school. AI will automatically generate a compelling description. if the content doesnt meet your expectations you can simply click on the refresh icon and try again."
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                                {values.teachers.length < maxForms && (
                                                    <div className="flex justify-center mt-4">
                                                        <button
                                                            type="button"
                                                            onClick={() => push(emptyTeachersData)}
                                                            style={{
                                                                color: 'rgba(48, 38, 38, 0.9)',
                                                                backgroundColor: 'white',
                                                            }}
                                                            className="bg-gray-400 text-gray-700 font-bold text-lg rounded-full w-12 h-12 flex items-center justify-center hover:bg-gray-300"
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>

                            <div className="flex justify-end items-center gap-4  mb-10 pr-12 sm:justify-center sm:gap-2 sm:px-4 w-full">
                                <button type="button" className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-white py-2 px-4 rounded-lg" style={{ color: 'white' }} onClick={() => resetForm()}>RESET</button>
                                <button className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-white py-2 px-4 rounded-lg" style={{ color: 'white' }} type="submit">PROCEED</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
            formHeading={undefined}
        />
    );


};

export default Teachers;