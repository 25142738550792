import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import Input from "components/Input";
import { Button } from "components";
import TextArea from "components/TextArea";
import ImageUpload from "components/ImageUpload";
import Swal from "sweetalert2";
import FormWrapper from "../component/FormWrapper";

const Testimonials = () => {
    const maxForms = 5;
    const emptyTestimonialData = {
        TESTIMONIALS_IMAGE: "",
        TESTIMONIALS_NAME: "",
        TESTIMONIALS_TESTIMONIAL: "",
        TESTIMONIALS_RATING: 0,
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(localStorage.getItem("testimonials")) || [];
        return savedData.length ? savedData : [emptyTestimonialData];
    };

    return (
        <FormWrapper
            formHeading={<div className="flex flex-col gap-4 justify-center items-center">
                <p className="text-4xl">Hear it from the Heart!</p>
                <div className="mx-12 w-[50%] text-center">
                    <p className="text-xl font-thin">
                        Nothing speaks louder than the voices of the parents who trust your institution. Showcase the experiences and words that celebrate your school's impact on their children's lives.</p>
                </div>
                <p className="text-[1.2vw] italic mt-1 text-gray-500 font-thin">
                    (Maximum 5 Recognition Details)
                </p>
            </div>}
            childern={<Formik
                initialValues={{ testimonials: loadInitialData() }}
                onSubmit={(values) => {
                    localStorage.setItem("testimonials", JSON.stringify(values.testimonials));
                    Swal.fire({
                        title: "Continue! You're creating a legacy for the students !",
                        icon: "success",
                        confirmButtonText: "PROCEED",
                    });
                }}
            >
                {({ values, handleSubmit, setFieldValue, resetForm }) => (
                    <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <div className="flex flex-col justify-center items-center gap-4 bg-slate-50 rounded-3xl my-8 mx-12 p-12">
                            <div className="w-full max-w-5xl">
                                <FieldArray name="testimonials">
                                    {({ push, remove }) => (
                                        <>
                                            {values.testimonials.map((testimonial, index) => (
                                                <div
                                                    key={index}
                                                    className="bg-white rounded-3xl p-14 w-full max-w-5xl z-1000 mb-4"
                                                >
                                                    <div
                                                        className="relative flex flex-col gap-6 bg-white rounded-3xl p-10"
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        {values.testimonials.length > 1 && (
                                                            <button
                                                                type="button"
                                                                className="absolute top-4 right-4 bg-slate-200 text-slate-700 font-bold text-[1.8vw] rounded-full w-10 h-10 flex items-center justify-center hover:bg-slate-300"
                                                                onClick={() => remove(index)}
                                                            >
                                                                -
                                                            </button>
                                                        )}
                                                        <div className="flex justify-center w-full">
                                                            <div className="w-[40%]">
                                                                <Field name={`testimonials.${index}.TESTIMONIALS_IMAGE`}>
                                                                    {({ field, form }) => (
                                                                        <ImageUpload
                                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                            imageValue={field.value}
                                                                            onChange={(item) =>
                                                                                form.setFieldValue(`testimonials.${index}.TESTIMONIALS_IMAGE`, item)
                                                                            }
                                                                            wantHDOption
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>

                                                        <Input
                                                            label="Parent's Name"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`testimonials.${index}.TESTIMONIALS_NAME`}
                                                            type="text"
                                                            placeHolder="Enter Parent's name"
                                                            required
                                                        />

                                                        <TextArea
                                                            label="Share Their Testimonial"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`testimonials.${index}.TESTIMONIALS_TESTIMONIAL`}
                                                            placeHolder="eg: I am incredibly pleased with the education my child is receiving at this school. The teachers are highly qualified and dedicated to helping students succeed, and the school provides a nurturing and supporting environment."
                                                            required
                                                        />

                                                        <div className="space-y-2">
                                                            <p className="text-center font-bold text-[1.3vw] pb-3">Rate Their Experience</p>
                                                            <div className="flex justify-center gap-4">
                                                                {[1, 2, 3, 4, 5].map((star) => (
                                                                    <span
                                                                        key={star}
                                                                        className={`text-[3vw] cursor-pointer font-bold ${testimonial.TESTIMONIALS_RATING >= star
                                                                            ? "text-yellow-500"
                                                                            : "text-gray-300"
                                                                            }`}
                                                                        onClick={() =>
                                                                            setFieldValue(
                                                                                `testimonials.${index}.TESTIMONIALS_RATING`,
                                                                                star
                                                                            )
                                                                        }
                                                                    >
                                                                        ★
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {values.testimonials.length < maxForms && (
                                                <div className="flex justify-center mt-4">
                                                    <button
                                                        type="button"
                                                        onClick={() => push(emptyTestimonialData)}
                                                        className="bg-slate-400 text-slate-700 font-bold text-[1.8vw] rounded-full w-14 h-14 flex items-center justify-center hover:bg-slate-300"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </div>
                        <div className="flex justify-end gap-4 p-8 mx-6 mb-6">
                            <button
                                type="button"
                                className="bg-slate-600 text-[white] py-2 px-4 rounded-lg"
                                onClick={() => resetForm()}
                            >
                                RESET
                            </button>
                            <button className="bg-sky-600 text-[white] py-2 px-4 rounded-lg">
                                PROCEED
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>}
        />
    );
};

export default Testimonials;