import React from "react";
import { Field, Form, Formik } from 'formik';
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import { GoogleMaps } from "components/GoogleMaps";
import Swal from "sweetalert2";
import FormWrapper from "../component/FormWrapper";

const INSTITUTE_OPTIONS = [
    { value: "School", label: "School" },
    { value: "College", label: "College" },
    { value: "Degree College", label: "Degree College" },
    { value: "PG College", label: "PG College" },
    { value: "Others", label: "Others" },
];

const BOARD_TYPE = [
    { value: "CBSE", label: "CBSE" },
    { value: "ICSE", label: "ICSE" },
    { value: "State Board", label: "State Board" },
    { value: "Others", label: "Others" },
];

const ADDON_PROGRAMS = [
    { value: "CA", label: "CA" },
    { value: "ICWA", label: "ICWA" },
    { value: "CS", label: "CS" },
    { value: "NDA", label: "NDA" },
    { value: "AIMS", label: "AIMS" },
    { value: "Nalsar", label: "Nalsar" },
];

const INSTRUCTION_MEDIUM = [
    { value: "ENGLISH", label: "ENGLISH" },
    { value: "TELUGU", label: "TELUGU" }
];

const GRADE_LEVELS = [
    { value: "Grade 1", label: "Grade 1" },
    { value: "Grade 2", label: "Grade 2" },
    { value: "Grade 3", label: "Grade 3" },
    { value: "Grade 4", label: "Grade 4" },
    { value: "Grade 5", label: "Grade 5" },
    { value: "Grade 6", label: "Grade 6" },
    { value: "Grade 7", label: "Grade 7" },
    { value: "Grade 8", label: "Grade 8" },
    { value: "Grade 9", label: "Grade 9" },
    { value: "Grade 10", label: "Grade 10" },
    { value: "Grade 11", label: "Grade 11" },
];

const initialValues = {
    NAME: "",
    LOGO: "",
    GOOGLE_MAP_POSITION_NAME: "",
    GOOGLE_MAP_POSITION: "",
    INSTITUTE_TYPE: "",
    BOARD_TYPE: "",
    ADDON_PROGRAMS: [],
    FOUNDING_DATE: "",
    INSTRUCTION_MEDIUM: [],
    GRADE_LEVELS: [],
}

const LandingForm = () => {
    return (
        <FormWrapper
            childern={<div className="relative">
                <Formik
                    initialValues={initialValues}
                    onSubmit={values => {
                        console.log("Form submitted with values: ", values);
                        localStorage.setItem("Institute", JSON.stringify(values));
                        Swal.fire({
                            title: "Don't Worry! Your progress is automatically saved!",
                            icon: "success",
                            confirmButtonText: "PROCEED",
                        });
                    }}
                >
                    {({ values, handleChange, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="bg-onboardingBackground rounded-3xl p-6 text-white">
                                <div className="flex flex-col gap-2 justify-center items-center mb-4" style={{ color: 'white' }}>
                                    <p className="text-lg font-bold bg-clip-text text-white mb-2 text-center">
                                        Let's Build a Bold Digital Impression!
                                    </p>
                                    <div className="w-full text-center">
                                        <p className="text-xs font-thin text-white">
                                            Share Vital Details – Build a Strong Foundation for Your Online Presence
                                        </p>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-1">
                                    {/* Column 1 */}
                                    <div className="flex flex-col gap-3  object-contain">
                                        <Input
                                            name="NAME"
                                            backgroundColor="white"
                                            label="Institution Name"
                                            placeHolder="Enter your name here"
                                            required />
                                        <div className="w-full py-[1.8px]">
                                            <Field name="LOGO">
                                                {({ field, form }) => (
                                                    <ImageUpload
                                                        label="Logo"
                                                        // containerHeight="h-1/3"
                                                        imageValue={field.value}
                                                        onChange={item => {
                                                            form.setFieldValue('LOGO', item);
                                                        }} />
                                                )}
                                            </Field>
                                        </div>
                                        <Input
                                            name="FOUNDING_DATE"
                                            type="date"
                                            backgroundColor="white"
                                            label="Founding Date"
                                            placeHolder="DD/MM/YY"
                                            required />
                                        <Input
                                            mapField
                                            name="GOOGLE_MAP_POSITION_NAME"
                                            mapName="GOOGLE_MAP_POSITION"
                                            backgroundColor="white"
                                            label="Location"
                                            placeHolder="Enter location"
                                        />

                                    </div>

                                    {/* Column 2 */}
                                    <div className="flex flex-col gap-3 ">
                                        {/* <Input
                                            mapField
                                            name="GOOGLE_MAP_POSITION_NAME"
                                            mapName="GOOGLE_MAP_POSITION"
                                            backgroundColor="white"
                                            label="Location"
                                            placeHolder="Enter location"
                                        /> */}

                                        <Dropdown
                                            name="INSTRUCTION_MEDIUM"
                                            label="Medium of Instruction"
                                            placeHolder="Select Medium"
                                            required
                                            isMulti
                                            options={INSTRUCTION_MEDIUM} />
                                        <Dropdown
                                            name="INSTITUTE_TYPE"
                                            label="Institution Type"
                                            placeHolder="Pick Institution Type"
                                            options={INSTITUTE_OPTIONS} />
                                        <Dropdown
                                            name="GRADE_LEVELS"
                                            label="Grade/Class Levels"
                                            placeHolder="Pick Grades"
                                            required
                                            isMulti
                                            options={GRADE_LEVELS} />
                                        <Dropdown
                                            name="BOARD_TYPE"
                                            label="Board Type"
                                            placeHolder="Pick Board Type"
                                            options={BOARD_TYPE} />
                                        <Dropdown
                                            name="ADDON_PROGRAMS"
                                            label="AddOn Programs"
                                            placeHolder="Select Programs"
                                            isMulti
                                            options={ADDON_PROGRAMS} />
                                    </div>

                                    {/* Column 3 */}
                                    <div className="flex flex-col gap-4 h-full justify-between">
                                        <div className="flex flex-col rounded-2xl border border-solid border-black-900 overflow-hidden">
                                            <Field name="GOOGLE_MAP_POSITION">
                                                {({ field, form }) => (
                                                    <div className="h-[255px]  overflow-hidden">
                                                        <GoogleMaps
                                                            onLocationClick={e => {
                                                                form.setFieldValue('GOOGLE_MAP_POSITION', e);
                                                                form.setFieldValue('GOOGLE_MAP_POSITION_NAME', "");
                                                            }}
                                                            selectedLocation={field.value}
                                                            initialLocation={field.value}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                            <div className="bg-[white] py-4 px-12 text-center">
                                                <p className="text-sm text-gray-500">Click the Location Icon and Proceed with Pinning the Location   <br /> (Home,Work, Others)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end items-center gap-4 mt-4 sm:justify-center">
                                <button
                                    type="button"
                                    className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                                    onClick={() => resetForm()}
                                    style={{ color: 'white' }}
                                >
                                    RESET
                                </button>
                                <button
                                    type="submit"
                                    className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                                    style={{ color: 'white' }}
                                >
                                    PROCEED
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>} formHeading={undefined} />
    );
}

export default LandingForm;