import React from "react";
import { Link } from "react-router-dom";

const FormWrapper: ({ title, childern, formHeading }: {
    title?: any;
    childern: any;
    formHeading: any;
}) => React.JSX.Element = ({ title, childern, formHeading }) => {
    return (
        <div className="container mx-auto">
            <nav className="flex w-full justify-between items-center bg-slate-100 p-2">
                <div className="flex w-full items-center gap-4">
                    <Link to="/" style={{ width: '4.68vw' }}>
                        <img
                            className="w-[3vw] h-[3vw] object-contain mx-auto"
                            src={"images/Logo.svg"}
                            alt="SooMuch Logo"
                        />
                    </Link>
                    <p className="font-bold">Onboarding Setup</p>
                </div>
            </nav>
            {!!title && title}
            <div className="bg-slate-50 rounded-3xl my-8 mx-12 p-12">
                {!!formHeading && formHeading}
                {childern}
            </div>
        </div>
    );
}

export default FormWrapper;