import React from "react";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from 'formik';
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import { GoogleMaps } from "components/GoogleMaps";
import Swal from "sweetalert2";
import FormWrapper from "../component/FormWrapper";

const INSTITUTE_OPTIONS = [
    { value: "School", label: "School" },
    { value: "College", label: "College" },
    { value: "Degree College", label: "Degree College" },
    { value: "PG College", label: "PG College" },
    { value: "Others", label: "Others" },
];

const BOARD_TYPE = [
    { value: "CBSE", label: "CBSE" },
    { value: "ICSE", label: "ICSE" },
    { value: "State Board", label: "State Board" },
    { value: "Others", label: "Others" },
];

const ADDON_PROGRAMS = [
    { value: "CA", label: "CA" },
    { value: "ICWA", label: "ICWA" },
    { value: "CS", label: "CS" },
    { value: "NDA", label: "NDA" },
    { value: "AIMS", label: "AIMS" },
    { value: "Nalsar", label: "Nalsar" },
];

const INSTRUCTION_MEDIUM = [
    { value: "ENGLISH", label: "ENGLISH" },
    { value: "TELUGU", label: "TELUGU" }
];

const GRADE_LEVELS = [
    { value: "Grade 1", label: "Grade 1" },
    { value: "Grade 2", label: "Grade 2" },
    { value: "Grade 3", label: "Grade 3" },
    { value: "Grade 4", label: "Grade 4" },
    { value: "Grade 5", label: "Grade 5" },
    { value: "Grade 6", label: "Grade 6" },
    { value: "Grade 7", label: "Grade 7" },
    { value: "Grade 8", label: "Grade 8" },
    { value: "Grade 9", label: "Grade 9" },
    { value: "Grade 10", label: "Grade 10" },
    { value: "Grade 11", label: "Grade 11" },
];

const initialValues = {
    NAME: "",
    LOGO: "",
    GOOGLE_MAP_POSITION_NAME: "",
    GOOGLE_MAP_POSITION: "",
    INSTITUTE_TYPE: "",
    BOARD_TYPE: "",
    ADDON_PROGRAMS: [],
    FOUNDING_DATE: "",
    INSTRUCTION_MEDIUM: [],
    GRADE_LEVELS: [],
}

const LandingForm = () => {
    return  <FormWrapper
    title={ <div className="flex flex-col jusitfy-center items-center gap-4 bg-slate-50 rounded-3xl my-8 mx-12 p-12">
        <p className="text-6xl text-center">Welcome to SOOMUCH.AI Onboarding!</p>
        <div className="mx-12 w-[70%] text-center">
            <p className="text-[2vw]">Let’s optimize your school’s online presence and operational efficiency with ease.</p>
        </div>
    </div>}
    formHeading={ <div className="flex flex-col gap-4 justify-center items-center">
        <p className="text-4xl">Let’s Build a Bold Digital Impression!</p>
        <div className="mx-12 w-[50%] text-center">
            <p className="text-xl font-thin">Share Vital Details – Build a Strong Foundation for Your Online Presence</p>
        </div>
    </div>}
    childern={<Formik
        initialValues={initialValues}
        // validationSchema={validationSchema(demoCampaignID?.DEMO_CAMPAIGN_ID)}
        onSubmit={values => {
            console.log("values ", values);
            Swal.fire({
                title: "Don’t Worry! Your progress is automatically saved!",
                icon: "success",
                confirmButtonText: "PROCEED",
            });
        }}
    >
        {({ values, handleChange, resetForm }) => {
            console.log("values ", values);
            return <Form className="flex flex-col gap-4 my-5">
                <div className="grid grid-cols-2 gap-x-8 gap-y-12 sm:grid-cols-1">
                    <div className="flex flex-col gap-x-8 gap-y-12">
                        <Input
                            name="NAME"
                            backgroundColor="white"
                            label="What's Your Name?"
                            placeHolder="Enter your name here"
                            required
                        />
                        <div className="w-[60%]">
                            <Field name="LOGO">
                                {({ field, form, meta }) => <ImageUpload
                                    label={"Logo That Speaks Your Legacy!"}
                                    imageValue={field.value}
                                    onChange={item => {
                                        form.setFieldValue('LOGO', item);
                                    }}
                                    wantHDOption
                                />}
                            </Field>
                        </div>
                        <Input
                            mapField
                            name="GOOGLE_MAP_POSITION_NAME"
                            mapName="GOOGLE_MAP_POSITION"
                            backgroundColor="white"
                            label="Where are you located?"
                            placeHolder="Enter the location manually (Or Pin in Map)"
                        // required
                        />
                        <Dropdown
                            name="INSTITUTE_TYPE"
                            label="Choose Your Institution Type!"
                            placeHolder="Pick Your Institution Type(e.g., School/College)"
                            required
                            // isMulti
                            options={INSTITUTE_OPTIONS}
                        />
                        <Dropdown
                            name="BOARD_TYPE"
                            label="What’s Your Board Type?"
                            placeHolder="Pick Your Board Type (e.g., CBSE / ICSE )"
                            required
                            // isMulti
                            options={BOARD_TYPE}
                        />
                        <Dropdown
                            name="ADDON_PROGRAMS"
                            label="Build Your Experts with AddOn Programs"
                            placeHolder="Pick AddOn Programs (e.g.,CA, ICWA ,etc)"
                            required
                            isMulti
                            options={ADDON_PROGRAMS}
                        />
                    </div>
                    <div className="flex flex-col gap-x-8 gap-y-12">
                        <div className="flex flex-col rounded-2xl border border-solid border-black-900 overflow-hidden">
                            <Field name="GOOGLE_MAP_POSITION">
                                {({ field, form, meta }) => <GoogleMaps
                                    onLocationClick={e => {
                                        form.setFieldValue('GOOGLE_MAP_POSITION', e);
                                        form.setFieldValue('GOOGLE_MAP_POSITION_NAME', "");
                                    }}
                                    selectedLocation={field.value}
                                    initialLocation={field.value}
                                />}
                            </Field>
                            <div className="bg-[white] py-4 px-12 text-center">
                                <p className="text-sm text-gray-500">Click the Location Icon and Proceed with Pinning the Location (Home,Work, Others)</p>
                            </div>
                        </div>
                        <Input
                            name="FOUNDING_DATE"
                            type="date"
                            backgroundColor="white"
                            label="When Did Your Journey Begin?"
                            placeHolder="(e.g.,DD/MM/YY)"
                            required
                        />
                        <Dropdown
                            name="INSTRUCTION_MEDIUM"
                            label="Define Your Medium of Instruction!"
                            placeHolder="(e.g., English, Telugu)"
                            required
                            isMulti
                            options={INSTRUCTION_MEDIUM}
                        />
                        <Dropdown
                            name="GRADE_LEVELS"
                            label="What are Your School's Grade/ Class Levels?"
                            placeHolder="Pick Your Grade (e.g., Grade 1 - Grade 5)"
                            required
                            isMulti
                            options={GRADE_LEVELS}
                        />
                    </div>
                </div>
                <div className="flex justify-end items-center gap-4">
                    <button type="button" className="bg-slate-600 text-[white] py-2 px-4 rounded-lg" onClick={() => resetForm()}>RESET</button>
                    <button className="bg-sky-600 text-[white] py-2 px-4 rounded-lg">PROCEED</button>
                </div>
            </Form>
        }}
    </Formik>}   
/>;
}

export default LandingForm;