import React from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import Input from "components/Input";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import TextArea from "components/TextArea";

const Recognition = () => {
    const maxForms = 5;

    const emptyRecognitionData = {
        RECOGNITION_IMAGE: "",
        RECOGNITION_ACHIEVEMENT: "",
        RECOGNITION_ISSUED_BY: "",
        RECOGNITION_YEAR: "",
        RECOGNITION_DESCRIPTION: "", // Corrected field name
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(localStorage.getItem("recognitions")) || [];
        return savedData.length ? savedData : [emptyRecognitionData];
    };

    return (
        <FormWrapper
            childern={ // Corrected from 'childern'
                <Formik
                    initialValues={{ recognitions: loadInitialData() }}
                    onSubmit={(values) => {
                        // Corrected localStorage key
                        localStorage.setItem("recognitions", JSON.stringify(values.recognitions));
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form
                            onSubmit={handleSubmit}
                            className="flex flex-col gap-4"
                        >
                            <div
                                className="bg-onboardingBackground flex flex-col justify-center items-center gap-4 rounded-3xl sm:mx-2 p-6 sm:p-4"
                            >
                                <div className="w-full max-w-4xl sm:max-w-full">
                                    <FieldArray name="recognitions">
                                        {({ push, remove }) => (
                                            <>
                                                <div className="flex flex-col gap-4 justify-center items-center" style={{ color: 'white' }}>
                                                    <p className="text-2xl sm:text-xl leading-relaxed text-center font-bold text-white">
                                                        Celebrate Your School's Achievements!
                                                    </p>
                                                    <div className="mx-4 sm:mx-2 w-full sm:w-[90%] text-center leading-relaxed mb-6">
                                                        <p className="text-base sm:text-sm font-thin text-white">
                                                            Show off the accolades, awards, and recognitions that make your school stand out.
                                                        </p>
                                                        <p className="text-xs italic mt-2 text-gray-300">
                                                            (Maximum 5 Recognition Details)
                                                        </p>
                                                    </div>
                                                </div>
                                                {values.recognitions.map((recognitionElement, index) => (
                                                    <div
                                                        key={index}
                                                        className="bg-transparent rounded-3xl p-6 sm:p-4 w-full max-w-4xl sm:max-w-full mb-4"
                                                    >
                                                        <div
                                                            className="relative flex flex-col gap-4 bg-transparent rounded-3xl p-6 sm:p-4"
                                                            style={{
                                                                background: 'rgba(255, 255, 255, 0.2)',
                                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                backdropFilter: 'blur(10px)',
                                                                borderRadius: '30px',
                                                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                                            }}
                                                        >
                                                            {values.recognitions.length > 1 && (
                                                                <button
                                                                    type="button"
                                                                    style={{
                                                                        color: 'rgba(48, 38, 38, 0.9)',
                                                                        backgroundColor: 'white',
                                                                    }}
                                                                    className="absolute top-2 right-2 bg-white text-slate-700 font-bold text-base rounded-full w-8 h-8 flex items-center justify-center"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    x
                                                                </button>
                                                            )}
                                                            <div className="flex justify-center">
                                                                <div className="w-[60%] sm:w-[80%]">
                                                                    <Field name={`recognitions.${index}.RECOGNITION_IMAGE`}>
                                                                        {({ field, form }) => (
                                                                            <ImageUpload
                                                                                imageValue={field.value}
                                                                                onChange={(item) =>
                                                                                    form.setFieldValue(`recognitions.${index}.RECOGNITION_IMAGE`, item)
                                                                                }
                                                                                wantHDOption
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <Input
                                                                label="Feel Proud Of Saying Us Your Achievement"
                                                                labelClassNames="text-center font-bold text-base sm:text-sm pb-3"
                                                                name={`recognitions.${index}.RECOGNITION_ACHIEVEMENT`}
                                                                type="text"
                                                                placeHolder="Award name: e.g., Telangana Educational Excellence Award"
                                                                required
                                                            />
                                                            <Input
                                                                label="Who Issued the Award?"
                                                                labelClassNames="text-center font-bold text-base sm:text-sm pb-3"
                                                                name={`recognitions.${index}.RECOGNITION_ISSUED_BY`}
                                                                type="text"
                                                                placeHolder="Issued by: e.g., Telangana State Government"
                                                                required
                                                            />
                                                            <Input
                                                                label="In Which Year?"
                                                                labelClassNames="text-center font-bold text-base sm:text-sm pb-3"
                                                                name={`recognitions.${index}.RECOGNITION_YEAR`}
                                                                type="text"
                                                                placeHolder="Issue year: e.g., 2022-2023"
                                                                required
                                                            />
                                                            <TextArea
                                                                label="A Few Words About The Achievement"
                                                                labelClassNames="text-center font-bold text-base sm:text-sm pb-3"
                                                                name={`recognitions.${index}.RECOGNITION_DESCRIPTION`}
                                                                placeHolder="Showcasing the acheivements of our institution. AI will automatically generate the description. if the content doesnt meet your expectations, you can click the refresh icon to try again."
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                                {values.recognitions.length < maxForms && (
                                                    <div className="flex justify-center mt-4">
                                                        <button
                                                            type="button"
                                                            onClick={() => push(emptyRecognitionData)}
                                                            style={{
                                                                color: 'rgba(48, 38, 38, 0.9)',
                                                                backgroundColor: 'white',
                                                            }}
                                                            className="bg-gray-400 text-gray-700 font-bold text-lg rounded-full w-12 h-12 flex items-center justify-center hover:bg-gray-300"
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>

                            <div className="flex justify-end items-center gap-4  mb-10 pr-12 sm:justify-center sm:gap-2 sm:px-4 w-full">
                                <button type="button" className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-white py-2 px-4 rounded-lg" style={{ color: 'white' }} onClick={() => resetForm()}>RESET</button>
                                <button className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-white py-2 px-4 rounded-lg" style={{ color: 'white' }} type="submit">PROCEED</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
            formHeading={undefined}
        />
    );
};

export default Recognition;