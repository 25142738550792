import axios from "axios";
import { ErrorMessage, Field } from "formik";
import React, { useCallback } from "react";
import _ from "lodash";

const Input: ({ label, required, backgroundColor, name, type, placeHolder }: {
    label?: string;
    required?: boolean;
    backgroundColor?: string;
    name: string;
    mapName?: string;
    type?: string;
    placeHolder?: string;
    labelClassNames?: string;
    mapField?: boolean;
}) => React.JSX.Element = ({
    label, required, backgroundColor, name, type, placeHolder, mapField, mapName, labelClassNames
}) => {

        const updateLocationFromAddress = async (enteredAddress, name, form) => {
            try {

                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                        enteredAddress
                    )}&key=${process.env.REACT_APP_GOOGLE_API_KEY!}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response?.data?.results?.length > 0) {
                    const location = response?.data?.results?.[0]?.geometry?.location;
                    const formattedAddress = response?.data?.results?.[0]?.formatted_address;
                    form.setFieldValue(mapName, ({ lat: location.lat, lng: location.lng, address: formattedAddress }));
                }
            } catch (error) {
            }
        };

        const debouncedUpdateLocation = useCallback(
            _.debounce((item, mapName, form) => {
                updateLocationFromAddress(item, mapName, form);
            }, 500), // Delay of 900ms
            [mapName, updateLocationFromAddress]
        );

        const handleChange = (event, form) => {
            const item = event.target.value;

            if (mapField && !!item) {
                // Use debounce only when mapField is true
                debouncedUpdateLocation(item, mapName, form);
            }
            form.setFieldValue(name, item);
        };

        return <div className="flex flex-col gap-2">
            {!!label && <label className={`block text-gray-700 ${!!labelClassNames ? labelClassNames : ""}`}>
                {label} {!!required && <span className="text-red-700">*</span>}
            </label>}
            <div style={{ backgroundColor: backgroundColor || "white" }} className="w-[100%] border border-solid border-black-900 rounded-2xl py-1 px-2">
                <Field
                    name={name}
                >
                    {({ field, form, meta }) => <input
                        className="rounded-2xl h-10 w-[100%]"
                        placeholder={placeHolder || ""}
                        style={{ backgroundColor: backgroundColor || "white" }}
                        type={type || "text"}
                        required={required}
                        value={field?.value?.address || field.value}
                        onChange={item => handleChange(item, form)}
                    />}
                </Field>
            </div>
            <ErrorMessage name={name} component="div" className="text-red-600" />
        </div>;
    }

export default Input;