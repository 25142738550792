import React from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";
import TextArea from "components/TextArea";

const QUALIFICATIONS_OPTIONS = [
    { value: "B.Ed", label: "B.Ed" },
    { value: "M.Ed", label: "M.Ed" },
    { value: "Ph.D", label: "Ph.D" },
    { value: "M.Phil", label: "M.Phil" },
    { value: "B.Sc", label: "B.Sc" },
    { value: "M.Sc", label: "M.Sc" },
    { value: "B.A", label: "B.A" },
    { value: "M.A", label: "M.A" },
    { value: "B.Tech", label: "B.Tech" },
    { value: "M.Tech", label: "M.Tech" },
    { value: "Others", label: "Others" },
];

const Founders = () => {
    const maxForms = 5;
    const emptyLeaderData = {
        INSTITUTE_LEADER_IMAGE: "",
        INSTITUTE_LEADER_NAME: "",
        INSTITUTE_LEADER_DESIGNATION: "",
        INSTITUTE_LEADER_QUALIFICATION: "",
        INSTITUTE_LEADER_EXPERIENCE: "",
        INSTITUTE_LEADER_DESCRIPTION: "",
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(localStorage.getItem("founders")) || [];
        return savedData.length ? savedData : [emptyLeaderData];
    };

    return (
        <FormWrapper
            childern={
                <Formik
                    initialValues={{ founders: loadInitialData() }}
                    onSubmit={(values) => {
                        localStorage.setItem("founders", JSON.stringify(values.founders));
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form
                            onSubmit={handleSubmit}
                            className="flex flex-col gap-4"
                        >
                           <div
                                className="bg-onboardingBackground flex flex-col justify-center items-center gap-4 rounded-3xl sm:mx-2 p-6 sm:p-4"      
                            >
                                <div className="w-full max-w-5xl">
                                    <FieldArray name="founders">
                                        {({ push, remove }) => (
                                            <>
                                                <div className="flex flex-col gap-4 justify-center items-center" style={{ color: 'white' }}>
                                                    <p className="text-4xl leading-relaxed text-center font-bold text-white sm:text-2xl">
                                                        Founders/Leaders - The <br /> visionaries who shape the <br /> future of our country.
                                                    </p>
                                                    <div className="mx-12 w-[55%] text-center leading-relaxed mb-10 sm:w-full sm:mx-4">
                                                        <p className="text-lg font-thin text-white sm:text-base">
                                                            Upload Chairman, Principal and Vice principal photos who <br /> guides students to become
                                                            successful citizens.
                                                        </p>
                                                    </div>
                                                </div>
                                                {values.founders.map((leaderElement, index) => (
                                                    <div
                                                        key={index}
                                                        className="bg-transparent rounded-3xl p-14 w-full max-w-5xl z-1000 mb-4 sm:p-6"
                                                    >
                                                        <div
                                                            className="relative flex flex-col gap-6 bg-transparent rounded-3xl p-10 sm:p-6"
                                                            style={{
                                                                background: 'rgba(255, 255, 255, 0.2)',
                                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                backdropFilter: 'blur(10px)',
                                                                borderRadius: '50px',
                                                                border: '1px solid rgba(255, 255, 255, 0.3)'
                                                            }}
                                                        >
                                                            {values.founders.length > 1 && (
                                                                <button
                                                                    type="button"
                                                                    className="absolute top-4 right-4 bg-white text-slate-700 font-bold text-[1.8vw] rounded-full w-10 h-10 flex items-center justify-center sm:text-base sm:w-8 sm:h-8"
                                                                    style={{
                                                                        color: 'rgba(48, 38, 38, 0.9)',
                                                                        backgroundColor: 'white',
                                                                    }}
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    x
                                                                </button>
                                                            )}
                                                            <div className="flex justify-center">
                                                                <div className="w-[40%] sm:w-[75%]">
                                                                    <Field name={`founders.${index}.INSTITUTE_LEADER_IMAGE`}>
                                                                        {({ field, form }) => (
                                                                            <ImageUpload
                                                                                imageValue={field.value}
                                                                                onChange={(item) => {
                                                                                    form.setFieldValue(`founders.${index}.INSTITUTE_LEADER_IMAGE`, item);
                                                                                }}
                                                                                wantHDOption
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <Input
                                                                label="Name of the leader"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3 sm:text-base"
                                                                name={`founders.${index}.INSTITUTE_LEADER_NAME`}
                                                                type="text"
                                                                placeHolder="Enter Name of the Leader"
                                                            />
                                                            <Input
                                                                label="Designation of the Leader"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3 sm:text-base"
                                                                name={`founders.${index}.INSTITUTE_LEADER_DESIGNATION`}
                                                                type="text"
                                                                placeHolder="e.g., Chairman, Principal or vice principal"
                                                            />
                                                            <Dropdown
                                                                label="Their Qualification"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3 sm:text-base"
                                                                name={`founders.${index}.INSTITUTE_LEADER_QUALIFICATION`}
                                                                placeHolder="Enter Qualification Here"
                                                                options={QUALIFICATIONS_OPTIONS}
                                                                required
                                                            />
                                                            <Input
                                                                label="How Many Years of Experience Do They Have?"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3 sm:text-base"
                                                                name={`founders.${index}.INSTITUTE_LEADER_EXPERIENCE`}
                                                                type="text"
                                                                placeHolder="Enter years of experience"
                                                            />
                                                            <TextArea
                                                                label="A Few Words About Them"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3 sm:text-base"
                                                                name={`founders.${index}.INSTITUTE_LEADER_DESCRIPTION`}
                                                                placeHolder="Experience the leadership of our chairman . AI wil automatically generate a compelling description. if the content doesnt meet your expectations you can simply click on the refresh icon and try again."
                                                            />
                                                        </div>
                                                    </div>
                                                ))}

                                                {values.founders.length < maxForms && (
                                                    <div className="flex justify-center mt-4">
                                                        <button
                                                            type="button"
                                                            onClick={() => push(emptyLeaderData)}
                                                            className="bg-slate-400 text-slate-700 font-bold text-[1.8vw] rounded-full w-14 h-14 flex items-center justify-center hover:bg-slate-300 sm:w-10 sm:h-10 sm:text-base"
                                                            style={{
                                                                color: 'rgba(48, 38, 38, 0.9)',
                                                                backgroundColor: 'white',
                                                            }}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>
                            <div className="flex justify-end items-center gap-4 mb-10 pr-12 sm:justify-center sm:gap-2 sm:px-4 w-full">
                                <button type="button" className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-white py-2 px-4 rounded-lg" onClick={() => resetForm()}
                                    style={{ color: 'white' }}
                                    >RESET</button>
                                <button className="bg-gradient-to-r from-[#844B9F] to-[#A93483] text-white py-2 px-4 rounded-lg" type="submit"
                                style={{ color: 'white' }}
                                >PROCEED</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
            formHeading={undefined}
        />
    );
};

export default Founders;