import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";

const QUALIFICATIONS_OPTIONS = [
    { value: "B.Ed", label: "B.Ed" },
    { value: "M.Ed", label: "M.Ed" },
    { value: "Ph.D", label: "Ph.D" },
    { value: "M.Phil", label: "M.Phil" },
    { value: "B.Sc", label: "B.Sc" },
    { value: "M.Sc", label: "M.Sc" },
    { value: "B.A", label: "B.A" },
    { value: "M.A", label: "M.A" },
    { value: "B.Tech", label: "B.Tech" },
    { value: "M.Tech", label: "M.Tech" },
    { value: "Others", label: "Others" },
]

const Leaders = () => {
    const maxForms = 5;
    const emptyLeaderData = {
        INSTITUTE_LEADER_LOGO: "",
        INSTITUTE_LEADER_NAME: "",
        INSTITUTE_LEADER_DESIGNATION: "",
        INSTITUTE_LEADER_QUALIFICATION: "",
        INSTITUTE_LEADER_EXPERIENCE: "",
        INSTITUTE_LEADER_DESCRIPTION: "",
        INSTITUTE_LEADER_SIGN: "",
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(localStorage.getItem("leader")) || [];
        return savedData.length ? savedData : [emptyLeaderData];
    };

    return (
        <FormWrapper
            formHeading={<div className="flex flex-col gap-4 justify-center items-center">
                <p className="text-4xl">You Are the Guiding Star of Tomorrow's Leaders</p>
                <div className="mx-12 w-[50%] text-center">
                    <p className="text-xl font-thin">Empowering Leaders – Let’s Upload The Leader’s Details (Chairman, Principal, Vice Principal,etc.,) - Arrange according to Your Required Hierarchy</p>
                </div>
            </div>}
            childern={
                <Formik
                    initialValues={{ leader: loadInitialData() }}
                    onSubmit={(values) => {
                        localStorage.setItem("leader", JSON.stringify(values.leader));
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className="flex flex-col justify-center items-center gap-4 bg-slate-50 rounded-3xl my-8 mx-12 p-12">
                                <div className="w-full max-w-5xl">
                                    <FieldArray name="leader">
                                        {({ push, remove }) => (
                                            <>
                                                {values.leader.map((leaderElement, index) => (
                                                    <div
                                                        key={index}
                                                        className="bg-white rounded-3xl p-14 w-full max-w-5xl z-1000 mb-4"
                                                    >
                                                        <div
                                                            className="relative flex flex-col gap-6 bg-white rounded-3xl p-10"
                                                            style={{ backgroundColor: "white" }}
                                                        >
                                                            {values.leader.length > 1 && (
                                                                <button
                                                                    type="button"
                                                                    className="absolute top-4 right-4 bg-slate-200 text-slate-700 font-bold text-[1.8vw] rounded-full w-10 h-10 flex items-center justify-center hover:bg-slate-300"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    -
                                                                </button>
                                                            )}
                                                            <div className="flex justify-center">
                                                                <div className="w-[40%]">
                                                                    <Field name={`leader.${index}.INSTITUTE_LEADER_LOGO`}>
                                                                        {({ field, form, meta }) => <ImageUpload
                                                                            // label={"Logo That Speaks Your Legacy!"}
                                                                            imageValue={field.value}
                                                                            onChange={item => {
                                                                                form.setFieldValue(`leader.${index}.INSTITUTE_LEADER_LOGO`, item);
                                                                            }}
                                                                            wantHDOption
                                                                        />}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <Input
                                                                label="Name of the leader"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                name={`leader.${index}.INSTITUTE_LEADER_NAME`}
                                                                type="text"
                                                                placeHolder="Enter Name of the Leader of Your Institution"
                                                            // required
                                                            />
                                                            <Input
                                                                label="Designation of the Leader"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                name={`leader.${index}.INSTITUTE_LEADER_DESIGNATION`}
                                                                type="text"
                                                                placeHolder="e.g., Chairman,Principal or Vice Principal"
                                                            // required
                                                            />
                                                            <Dropdown
                                                                label="Their Qualification"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                name={`leader.${index}.INSTITUTE_LEADER_QUALIFICATION`}
                                                                placeHolder="Enter Qualification Here (e.g.,B.Ed.,M.Ed.,etc.,)"
                                                                options={QUALIFICATIONS_OPTIONS}
                                                                required
                                                            />
                                                            <Input
                                                                label="How many Years of Experience Do They Have ?"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                name={`leader.${index}.INSTITUTE_LEADER_EXPERIENCE`}
                                                                type="text"
                                                                placeHolder="Enter no.of years (e.g.,15 years / 16 years / etc.)"
                                                            // required
                                                            />
                                                            <Input
                                                                label="A Few Words About Them!"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                name={`leader.${index}.INSTITUTE_LEADER_DESCRIPTION`}
                                                                type="text"
                                                                placeHolder="Enter no.of years (15 years, 16 years, etc.)"
                                                            // required
                                                            />
                                                            <Field name={`leader.${index}.INSTITUTE_LEADER_SIGN`}>
                                                                {({ field, form, meta }) => <ImageUpload
                                                                    label={"Seal of Leadership: Just Drag ‘n’ Drop the Leader's Signature"}
                                                                    labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                    imageValue={field.value}
                                                                    onChange={item => {
                                                                        form.setFieldValue(`leader.${index}.INSTITUTE_LEADER_SIGN`, item);
                                                                    }}
                                                                    wantHDOption
                                                                />}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                ))}

                                                {values.leader.length < maxForms && (
                                                    <div className="flex justify-center mt-4">
                                                        <button
                                                            type="button"
                                                            onClick={() => push(emptyLeaderData)}
                                                            className="bg-slate-400 text-slate-700 font-bold text-[1.8vw] rounded-full w-14 h-14 flex items-center justify-center hover:bg-slate-300"
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>
                            <div className="flex justify-end gap-4 p-8 mx-6 mb-6">
                                <button type="button" className="bg-slate-600 text-[white] py-2 px-4 rounded-lg" onClick={() => resetForm()}>RESET</button>
                                <button className="bg-sky-600 text-[white] py-2 px-4 rounded-lg">PROCEED</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        />
        // <div className="container mx-auto">

        //     <Formik
        //         initialValues={{ leader: loadInitialData() }}
        //         onSubmit={(values) => {
        //             localStorage.setItem("leader", JSON.stringify(values.leader));
        //         }}
        //     >
        //         {({ values, handleSubmit, resetForm }) => (
        //             <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
        //                 <div className="flex flex-col justify-center items-center gap-4 bg-slate-50 rounded-3xl my-8 mx-12 p-12">
        //                     <p className="text-5xl font-bold">You Are the Guiding Star of Tomorrow's Leaders</p>
        //                     <div className="mx-5 w-[70%] text-center">
        //                         <p className="text-[1.4vw] font-thin ">
        //                             Empowering Leaders – Let’s Upload The Leader’s Details (Chairman, Principal, Vice Principal,etc.,) - Arrange according to Your Required Hierarchy
        //                         </p>
        //                     </div>

        //                     <div className="w-full max-w-5xl">
        //                         <FieldArray name="leader">
        //                             {({ push, remove }) => (
        //                                 <>
        //                                     {values.leader.map((leaderElement, index) => (
        //                                         <div
        //                                             key={index}
        //                                             className="bg-white rounded-3xl p-14 w-full max-w-5xl z-1000 mb-4"
        //                                         >
        //                                             <div
        //                                                 className="relative flex flex-col gap-6 bg-white rounded-3xl p-10"
        //                                                 style={{ backgroundColor: "white" }}
        //                                             >
        //                                                 {values.leader.length > 1 && (
        //                                                     <button
        //                                                         type="button"
        //                                                         className="absolute top-4 right-4 bg-slate-200 text-slate-700 font-bold text-[1.8vw] rounded-full w-10 h-10 flex items-center justify-center hover:bg-slate-300"
        //                                                         onClick={() => remove(index)}
        //                                                     >
        //                                                         -
        //                                                     </button>
        //                                                 )}
        //                                                 <div className="flex justify-center">
        //                                                     <div className="w-[40%]">
        //                                                         <Field name={`leader.${index}.INSTITUTE_LEADER_LOGO`}>
        //                                                             {({ field, form, meta }) => <ImageUpload
        //                                                                 // label={"Logo That Speaks Your Legacy!"}
        //                                                                 imageValue={field.value}
        //                                                                 onChange={item => {
        //                                                                     form.setFieldValue(`leader.${index}.INSTITUTE_LEADER_LOGO`, item);
        //                                                                 }}
        //                                                                 wantHDOption
        //                                                             />}
        //                                                         </Field>
        //                                                     </div>
        //                                                 </div>
        //                                                 <Input
        //                                                     label="Name of the leader"
        //                                                     labelClassNames="text-center font-bold text-[1.3vw] pb-3"
        //                                                     name={`leader.${index}.INSTITUTE_LEADER_NAME`}
        //                                                     type="text"
        //                                                     placeHolder="Enter Name of the Leader of Your Institution"
        //                                                 // required
        //                                                 />
        //                                                 <Input
        //                                                     label="Designation of the Leader"
        //                                                     labelClassNames="text-center font-bold text-[1.3vw] pb-3"
        //                                                     name={`leader.${index}.INSTITUTE_LEADER_DESIGNATION`}
        //                                                     type="text"
        //                                                     placeHolder="e.g., Chairman,Principal or Vice Principal"
        //                                                 // required
        //                                                 />
        //                                                 <Dropdown
        //                                                     label="Their Qualification"
        //                                                     labelClassNames="text-center font-bold text-[1.3vw] pb-3"
        //                                                     name={`leader.${index}.INSTITUTE_LEADER_QUALIFICATION`}
        //                                                     placeHolder="Enter Qualification Here (e.g.,B.Ed.,M.Ed.,etc.,)"
        //                                                     options={QUALIFICATIONS_OPTIONS}
        //                                                     required
        //                                                 />
        //                                                 <Input
        //                                                     label="How many Years of Experience Do They Have ?"
        //                                                     labelClassNames="text-center font-bold text-[1.3vw] pb-3"
        //                                                     name={`leader.${index}.INSTITUTE_LEADER_EXPERIENCE`}
        //                                                     type="text"
        //                                                     placeHolder="Enter no.of years (e.g.,15 years / 16 years / etc.)"
        //                                                 // required
        //                                                 />
        //                                                 <Input
        //                                                     label="A Few Words About Them!"
        //                                                     labelClassNames="text-center font-bold text-[1.3vw] pb-3"
        //                                                     name={`leader.${index}.INSTITUTE_LEADER_DESCRIPTION`}
        //                                                     type="text"
        //                                                     placeHolder="Enter no.of years (15 years, 16 years, etc.)"
        //                                                 // required
        //                                                 />
        //                                                 <Field name={`leader.${index}.INSTITUTE_LEADER_SIGN`}>
        //                                                     {({ field, form, meta }) => <ImageUpload
        //                                                         label={"Seal of Leadership: Just Drag ‘n’ Drop the Leader's Signature"}
        //                                                         labelClassNames="text-center font-bold text-[1.3vw] pb-3"
        //                                                         imageValue={field.value}
        //                                                         onChange={item => {
        //                                                             form.setFieldValue(`leader.${index}.INSTITUTE_LEADER_SIGN`, item);
        //                                                         }}
        //                                                         wantHDOption
        //                                                     />}
        //                                                 </Field>
        //                                             </div>
        //                                         </div>
        //                                     ))}

        //                                     {values.leader.length < maxForms && (
        //                                         <div className="flex justify-center mt-4">
        //                                             <button
        //                                                 type="button"
        //                                                 onClick={() => push(emptyLeaderData)}
        //                                                 className="bg-slate-400 text-slate-700 font-bold text-[1.8vw] rounded-full w-14 h-14 flex items-center justify-center hover:bg-slate-300"
        //                                             >
        //                                                 +
        //                                             </button>
        //                                         </div>
        //                                     )}
        //                                 </>
        //                             )}
        //                         </FieldArray>
        //                     </div>
        //                 </div>
        //                 <div className="flex justify-end gap-4 p-8 mx-6 mb-6">
        //                     <button type="button" className="bg-slate-600 text-[white] py-2 px-4 rounded-lg" onClick={() => resetForm()}>RESET</button>
        //                     <button className="bg-sky-600 text-[white] py-2 px-4 rounded-lg">PROCEED</button>
        //                 </div>
        //             </Form>
        //         )}
        //     </Formik>
        // </div>
    );
};

export default Leaders;