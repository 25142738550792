import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import Input from "components/Input";
import { Button } from "components";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";

const Recognition = () => {
    const maxForms = 5;

    const emptyRecognitionData = {
        RECOGNITION_IMAGE: "",
        RECOGNITION_ACHIEVEMENT: "",
        RECOGNITION_ISSUED_BY: "",
        RECOGNITION_YEAR: "",
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(localStorage.getItem("recognitions")) || [];
        return savedData.length ? savedData : [emptyRecognitionData];
    };

    return (
        <FormWrapper
            formHeading={<div className="flex flex-col gap-4 justify-center items-center">
                <p className="text-4xl">Celebrate Your School's Achievements!</p>
                <div className="mx-12 w-[50%] text-center">
                    <p className="text-xl font-thin">
                        Show off the accolades, awards, and recognitions
                        that make your school stand out. This is where your
                        school's achievements take center stage!</p>
                </div>
                <p className="text-[1.2vw] italic mt-1 text-gray-500 font-thin">
                    (Maximum 5 Recognition Details)
                </p>
            </div>}
            childern={  <Formik
                initialValues={{ recognitions: loadInitialData() }}
                onSubmit={(values) => {
                    localStorage.setItem("recognitions", JSON.stringify(values.recognitions));
                }}
            >

                {({ values, handleSubmit, resetForm }) => (
                    <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <div className="flex flex-col justify-center items-center gap-4 bg-slate-50 rounded-3xl my-8 mx-12 p-12">
                            <div className="w-full max-w-5xl">
                                <FieldArray name="recognitions">
                                    {({ push, remove }) => (
                                        <>
                                            {values.recognitions.map((recognition, index) => (
                                                <div
                                                    key={index}
                                                    className="bg-white rounded-3xl p-14 w-full max-w-5xl z-1000 mb-4"
                                                >
                                                    <div
                                                        className="relative flex flex-col gap-6 bg-white rounded-3xl p-10"
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        {values.recognitions.length > 1 && (
                                                            <button
                                                                type="button"
                                                                className="absolute top-4 right-4 bg-slate-200 text-slate-700 font-bold text-[1.8vw] rounded-full w-10 h-10 flex items-center justify-center hover:bg-slate-300"
                                                                onClick={() => remove(index)}
                                                            >
                                                                -
                                                            </button>
                                                        )}

                                                        <div className="flex justify-center w-full">
                                                            <div className="w-[40%]">
                                                                <Field name={`recognitions.${index}.RECOGNITION_IMAGE`}>
                                                                    {({ field, form }) => (
                                                                        <ImageUpload
                                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                            imageValue={field.value}
                                                                            onChange={(item) => form.setFieldValue(`recognitions.${index}.RECOGNITION_IMAGE`, item)}
                                                                            wantHDOption
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>

                                                        <Input
                                                            label="Tell us about your Achievement"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`recognitions.${index}.RECOGNITION_ACHIEVEMENT`}
                                                            type="text"
                                                            placeHolder="Award name: e.g., Telangana Educational Excellence Award"
                                                            required
                                                        />

                                                        <Input
                                                            label="Who Issued the Award?"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`recognitions.${index}.RECOGNITION_ISSUED_BY`}
                                                            type="text"
                                                            placeHolder="Issued by: e.g., Telangana State Government, Hyderabad educational council, etc."
                                                            required
                                                        />

                                                        <Input
                                                            label="Year of Achievement"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`recognitions.${index}.RECOGNITION_YEAR`}
                                                            type="text"
                                                            placeHolder="Issue year: e.g., 2022-2023, 2021-2022, etc."
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            ))}

                                            {values.recognitions.length < maxForms && (
                                                <div className="flex justify-center mt-4">
                                                    <button
                                                        type="button"
                                                        onClick={() => push(emptyRecognitionData)}
                                                        className="bg-slate-400 text-slate-700 font-bold text-[1.8vw] rounded-full w-14 h-14 flex items-center justify-center hover:bg-slate-300"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </div>
                        <div className="flex justify-end gap-4 p-8 mx-6 mb-6">
                            <button
                                type="button"
                                className="bg-slate-600 text-[white] py-2 px-4 rounded-lg"
                                onClick={() => resetForm()}
                            >
                                RESET
                            </button>
                            <button className="bg-sky-600 text-[white] py-2 px-4 rounded-lg">
                                PROCEED
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>}
        />
    );
};

export default Recognition;