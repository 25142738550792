import React from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import ImageUpload from "components/ImageUpload";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import TextArea from "components/TextArea";
import FormWrapper from "../component/FormWrapper";

const Brochures = () => {
    const maxForms = 5;

    const emptyBrochure = {
        MATERIAL_IMAGE: "",
        MATERIAL_TYPE: "",
        MATERIAL_NAME: "",
        MATERIAL_DESCRIPTION: "",
    };

    const MATERIAL_TYPE_OPTIONS = [
        { value: "brochure", label: "Brochure" },
        { value: "flyer", label: "Flyer" },
    ];

    const loadInitialData = () => {
        const savedData = JSON.parse(localStorage.getItem("materials")) || [];
        return savedData.length ? savedData : [emptyBrochure];
    };

    return (
        <FormWrapper
            title={<div className="flex flex-col jusitfy-center items-center gap-4 bg-slate-50 rounded-3xl my-8 mx-12 p-12">
                <p className="text-5xl font-bold">Brochures, Prospectuses & Flyers</p>
                <div className="mx-5 w-[70%] text-center">
                    <p className="text-[1.4vw] font-thin">Want Designs That Shines?</p>
                    <p
                        style={{
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "1.5rem",
                            marginTop: "2rem",
                            color: "black",
                            padding: "1rem",
                            width: "30%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                        }}
                    >
                        Design My Materials!
                    </p>
                </div>
            </div>}
            formHeading={<div className="flex flex-col gap-4 justify-center items-center">
                <p className="text-4xl">Have Materials Already?</p>
                <div className="mx-12 w-[50%] text-center">
                    <p className="text-xl font-thin">Let's Upload Them!</p>
                </div>
            </div>}
            childern={
                <Formik
                    initialValues={{ materials: loadInitialData() }}
                    onSubmit={(values) => {
                        localStorage.setItem("materials", JSON.stringify(values.materials));
                    }}
                >
                    {({ values, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className="flex flex-col justify-center items-center gap-4 bg-slate-50 rounded-3xl my-8 mx-12 p-12">
                                <div className="w-full max-w-5xl">
                                    <FieldArray name="materials">
                                        {({ push, remove }) => (
                                            <>
                                                {values.materials.map((material, index) => (
                                                    <div
                                                        key={index}
                                                        className="bg-white rounded-3xl p-14 w-full max-w-5xl z-1000 mb-4"
                                                    >
                                                        <div
                                                            className="relative flex flex-col gap-6 bg-white rounded-3xl p-10"
                                                            style={{ backgroundColor: "white" }}
                                                        >
                                                            {values.materials.length > 1 && (
                                                                <button
                                                                    type="button"
                                                                    className="absolute top-4 right-4 bg-slate-200 text-slate-700 font-bold text-[1.8vw] rounded-full w-10 h-10 flex items-center justify-center hover:bg-slate-300"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    -
                                                                </button>
                                                            )}

                                                            <div className="flex justify-center w-full">
                                                                <div className="w-[35%]">
                                                                    <Field name={`materials.${index}.MATERIAL_IMAGE`}>
                                                                        {({ field, form }) => (
                                                                            <ImageUpload
                                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                                imageValue={field.value}
                                                                                onChange={(item) =>
                                                                                    form.setFieldValue(
                                                                                        `materials.${index}.MATERIAL_IMAGE`,
                                                                                        item
                                                                                    )
                                                                                }
                                                                                wantHDOption
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>

                                                            <Input
                                                                label="Name of the Material"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                name={`materials.${index}.MATERIAL_NAME`}
                                                                type="text"
                                                                placeHolder="Enter the name of the material here (e.g., The Learning Hub)"
                                                                required
                                                            />

                                                            <Dropdown
                                                                label="Material Type"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                name={`materials.${index}.MATERIAL_TYPE`}
                                                                placeHolder="Select Material Type (e.g., Brochure, Flyer)"
                                                                options={MATERIAL_TYPE_OPTIONS}
                                                                required
                                                            />

                                                            <TextArea
                                                                label="Description"
                                                                labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                name={`materials.${index}.MATERIAL_DESCRIPTION`}
                                                                placeHolder="Discover the essence of our school through our informative and visually engaging brochure ! it offers a comprehensive overview of our school, its programs, and its facilities and core values."
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                ))}

                                                {values.materials.length < maxForms && (
                                                    <div className="flex justify-center mt-4">
                                                        <button
                                                            type="button"
                                                            onClick={() => push(emptyBrochure)}
                                                            className="bg-slate-400 text-slate-700 font-bold text-[1.8vw] rounded-full w-14 h-14 flex items-center justify-center hover:bg-slate-300"
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>
                            <div className="flex justify-end gap-4 p-8 mx-6 mb-6">
                                <button
                                    type="button"
                                    className="bg-slate-600 text-[white] py-2 px-4 rounded-lg"
                                    onClick={() => resetForm()}
                                >
                                    RESET
                                </button>
                                <button className="bg-sky-600 text-[white] py-2 px-4 rounded-lg">
                                    PROCEED
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>}
        />
    );
};

export default Brochures;
