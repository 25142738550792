import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import Input from "components/Input";
import { Button } from "components";
import TextArea from "components/TextArea";
import ImageUpload from "components/ImageUpload";
import FormWrapper from "../component/FormWrapper";

const Infrastructure = () => {
    const maxForms = 5;
    const emptyImageData = {
        INFRASTRUCTURE_IMAGE: "",
        INFRASTRUCTURE_TITLE: "",
        INFRASTRUCTURE_DESCRIPTION: "",
        INFRASTRUCTURE_ALT_TEXT: "",
    };

    const loadInitialData = () => {
        const savedData = JSON.parse(localStorage.getItem("infrastructure")) || [];
        return savedData.length ? savedData : [emptyImageData];
    };

    return (
        <FormWrapper
            formHeading={<div className="flex flex-col gap-4 justify-center items-center">
                <p className="text-4xl">Showcase Your School's Campus Excellence!</p>
                <div className="mx-12 w-[50%] text-center">
                    <p className="text-xl font-thin">
                        Upload photo of your school's vibrant spaces -
                        from classroom to playgrounds - and let us
                        highlight your school's environment with stunning
                        visuals.</p>
                </div>
                <p className="text-[1.2vw] italic mt-1 text-gray-500 font-thin">
                    (Maximum 5 Image Details)
                </p>
            </div>}
            childern={<Formik
                initialValues={{ infrastructure: loadInitialData() }}
                onSubmit={(values) => {
                    localStorage.setItem("infrastructure", JSON.stringify(values.infrastructure));
                }}
            >
                {({ values, handleSubmit, resetForm }) => (
                    <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <div className="flex flex-col justify-center items-center gap-4 bg-slate-50 rounded-3xl my-8 mx-12 p-12">
                            <div className="w-full max-w-5xl">
                                <FieldArray name="infrastructure">
                                    {({ push, remove }) => (
                                        <>
                                            {values.infrastructure.map((image, index) => (
                                                <div
                                                    key={index}
                                                    className="bg-white rounded-3xl p-14 w-full max-w-5xl z-1000 mb-4"
                                                >
                                                    <div
                                                        className="relative flex flex-col gap-6 bg-white rounded-3xl p-10"
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        {values.infrastructure.length > 1 && (
                                                            <button
                                                                type="button"
                                                                className="absolute top-4 right-4 bg-slate-200 text-slate-700 font-bold text-[1.8vw] rounded-full w-10 h-10 flex items-center justify-center hover:bg-slate-300"
                                                                onClick={() => remove(index)}
                                                            >
                                                                -
                                                            </button>
                                                        )}

                                                        <div className="flex justify-center w-full">
                                                            <div className="w-[40%]">
                                                                <Field name={`infrastructure.${index}.INFRASTRUCTURE_IMAGE`}>
                                                                    {({ field, form }) => (
                                                                        <ImageUpload
                                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                                            imageValue={field.value}
                                                                            onChange={(item) =>
                                                                                form.setFieldValue(`infrastructure.${index}.INFRASTRUCTURE_IMAGE`, item)
                                                                            }
                                                                            wantHDOption
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>

                                                        <Input
                                                            label="Give the image, A Title"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`infrastructure.${index}.INFRASTRUCTURE_TITLE`}
                                                            type="text"
                                                            placeHolder="(e.g., Classroom, Playground etc)"
                                                            required
                                                        />

                                                        <TextArea
                                                            label="Give the image, A Description"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`infrastructure.${index}.INFRASTRUCTURE_DESCRIPTION`}
                                                            placeHolder="Capture the joy and energy of your school's playground - a space where students learn, play and create a lifelong memories. Highlight the heart of outdoor fun and team spirit"
                                                            required
                                                        />

                                                        <Input
                                                            label="Alt text"
                                                            labelClassNames="text-center font-bold text-[1.3vw] pb-3"
                                                            name={`infrastructure.${index}.INFRASTRUCTURE_ALT_TEXT`}
                                                            type="text"
                                                            placeHolder="Describe the image (e.g., school playground with students)"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            ))}

                                            {values.infrastructure.length < maxForms && (
                                                <div className="flex justify-center mt-4">
                                                    <button
                                                        type="button"
                                                        onClick={() => push(emptyImageData)}
                                                        className="bg-slate-400 text-slate-700 font-bold text-[1.8vw] rounded-full w-14 h-14 flex items-center justify-center hover:bg-slate-300"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </div>
                        <div className="flex justify-end gap-4 p-8 mx-6 mb-6">
                            <button
                                type="button"
                                className="bg-slate-600 text-[white] py-2 px-4 rounded-lg"
                                onClick={() => resetForm()}
                            >
                                RESET
                            </button>
                            <button className="bg-sky-600 text-[white] py-2 px-4 rounded-lg">
                                PROCEED
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>}
        />
    );
};

export default Infrastructure;