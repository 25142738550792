import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import EffectiveCommunication from "./EffectiveCommunication";
import DigitalMarketing from "./DigitalMarketing";
import FeeCollectionProcess from "./FeeCollectionProcess";
import AiPoweredManagmentHub from "./AiPoweredManagmentHub";
import CustomerSatisfaction from "./CustomerSatisfaction";
import Privacy from "./Privacy";
import Modal from "components/Modal";
import AfterSubmitionModal from "components/Modal/AfterSubmitionModal";
import PaymentPage from "components/PaymentPage";
import axios from "axios";
import * as ModalR from "react-modal";
import { Button } from "components/Button";
import RefundPolicy from "./RefundPolicy";
import TermsOfService from "./TermsOfService";
import WhatsappCampaignForm from "components/WhatsappCampaign/WhatsappCampaignForm";
import LandingForm from "pages/Onboarding/LandingForm";
import Faculties from "pages/Onboarding/Faculties";
import Infrastructure from "pages/Onboarding/Infrastructure";
import Recognition from "pages/Onboarding/Recognition";
import Testimonials from "pages/Onboarding/Testimonials";
import Leaders from "pages/Onboarding/Leaders";
import Brochures from "pages/Onboarding/Brochures";

const customStylesRefer = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "55rem", // Adjusted for responsiveness
    maxWidth: "90%", // Added to maintain a max width
    minHeight: "6rem",
    borderRadius: "1em",
    boxShadow: "0px 16px 32px 4px rgba(0, 0, 0, 0.15)",
    padding: "0px",
    border: "0px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
    zIndex: "999",
  },
  "@media (min-width: 768px)": {
    content: {
      width: "75%", // Adjusted for tablet view
    },
  },
  "@media (min-width: 1024px)": {
    content: {
      width: "55rem", // Original width for desktop view
    },
  },
};

const customStylesAskModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30rem",
    maxWidth: "80%",
    minHeight: "10rem",
    borderRadius: "1.5em",
    boxShadow: "0px 16px 32px 4px rgba(0, 0, 0, 0.15)",
    padding: "1.3em",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
    zIndex: 999,
  },
};

const SOURCE_NAME = {
  "/": "COMM",
  "/customer-satisfaction": "FEEDBACK",
  "/fee-collection-process": "FEE",
};

function generateUniqueKey(length = 10) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uniqueKey = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueKey += characters[randomIndex];
  }
  return uniqueKey;
}

export default function Main() {
  const [bookFormModal, setbookFormModal] = React.useState<{
    display: boolean;
    ID: number | undefined;
  }>({ display: false, ID: undefined });
  const [formSuccessModal, setFormSuccessModal] = React.useState<{
    display: boolean;
    ID: number | undefined;
  }>({ display: false, ID: undefined });
  const [reRequestLiveDemo, setReRequestLiveDemo] = React.useState<{
    display: boolean;
    ID: number | undefined;
  }>({ display: false, ID: undefined });

  let demoData: any = "";

  try {
    demoData = JSON.parse(localStorage.getItem("demo_data")) || "";
  } catch (err) {
    console.log("err ", err);
  }

  const handleReRequestLiveDemo = async () => {
    localStorage.setItem(
      "demo_data",
      JSON.stringify({
        ...demoData,
        SOURCE_ARRAY: !!demoData?.SOURCE_ARRAY?.length
          ? [
            ...demoData?.SOURCE_ARRAY,
            SOURCE_NAME?.[window?.location?.pathname],
          ]
          : [demoData?.SOURCE, SOURCE_NAME?.[window?.location?.pathname]],
      })
    );
    const campaginDetailResponse = await axios.post(
      `https://stg.soomuch.ai/api/v1/master/demoCampaignDetail/insert`,
      {
        ...demoData,
        SOURCE: SOURCE_NAME?.[window?.location?.pathname],
      }
    );
    handleSuccessModal({ display: false, ID: 0 });
  };

  const handleModal = async () => {
    if (demoData?.DEMO_CAMPAIGN_ID) {
      if (
        demoData?.SOURCE === SOURCE_NAME?.[window?.location?.pathname] ||
        demoData?.SOURCE_ARRAY?.includes(
          SOURCE_NAME?.[window?.location?.pathname]
        )
      ) {
        setReRequestLiveDemo({ display: true, ID: 0 });
      } else {
        handleReRequestLiveDemo();
      }
    } else {
      setbookFormModal({ display: true, ID: 0 });
    }
  };

  const handleSuccessModal = (e) => {
    setbookFormModal(e);
    setFormSuccessModal({ display: true, ID: 0 });
  };

  useEffect(() => {
    // Generate a unique identifier for the session
    const sessionKey = "sessionKey";
    const sessionValue = generateUniqueKey();

    if (
      sessionStorage.getItem(sessionKey) !== localStorage.getItem(sessionKey)
    ) {
      localStorage.clear();
      sessionStorage.clear();
    }
    // Set the session value if it's not already set
    if (!sessionStorage.getItem(sessionKey)) {
      sessionStorage.setItem(sessionKey, sessionValue);
    }
    if (!localStorage.getItem(sessionKey)) {
      localStorage.setItem(sessionKey, sessionValue);
    }
    const handleBeforeUnload = (event) => {
      // If the session storage does not have the session key, it's a tab close event
      if (!sessionStorage.getItem(sessionKey)) {
        localStorage.clear();
        sessionStorage.clear();
      }

      // Optionally, you can show a confirmation message
      event.returnValue = ""; // This line is necessary for some browsers to show a confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  function ConditionalRoutes() {
    const location = useLocation();

    // Check if the path starts with "/onboarding"
    const isOnboarding = location.pathname.startsWith("/onboarding");

    if (isOnboarding) {
      return (
        <Routes>
          <Route path="/onboarding" element={<LandingForm />} />                    //screen1
          <Route path="/onboarding/leaders" element={<Leaders />} />                //screen2
          <Route path="/onboarding/infrastructure" element={<Infrastructure />} />  //screen4
          <Route path="/onboarding/faculties" element={<Faculties />} />            //screen5
          <Route path="/onboarding/recognition" element={<Recognition />} />        //screen6
          <Route path="/onboarding/testimonials" element={<Testimonials />} />      //screen7
          <Route path="/onboarding/brochure" element={<Brochures />} />             //screen9
        </Routes>
      );
    }

    return (
      <>
        <Header />
        <Routes>
          <Route
            index
            element={<EffectiveCommunication handleModal={handleModal} />}
          />
          <Route path="/digital-marketing" element={<DigitalMarketing handleModal={handleModal} />} />
          <Route path="/customer-satisfaction" element={<CustomerSatisfaction handleModal={handleModal} />} />
          <Route path="/fee-collection-process" element={<FeeCollectionProcess handleModal={handleModal} />} />
          <Route path="/ai-powered-managment-hub" element={<AiPoweredManagmentHub handleModal={handleModal} />} />
          <Route path="/demo-payment" element={<PaymentPage />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/demo-whatsapp-campaign" element={<WhatsappCampaignForm />} />
        </Routes>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Soomuch{/* Revolutionize Education with Our Ecosystem  */}
          {/* - AUTO CONNECT */}
        </title>
        <meta
          name="description"
          content="Discover India's first business ecosystem tailored for educational institutions. Enhance communication, streamline fee collection, and boost digital marketing with AUTO CONNECT. Book a live demo today."
        />
      </Helmet>
      {reRequestLiveDemo.display && (
        <ModalR
          isOpen={reRequestLiveDemo.display}
          contentLabel="Example Modal"
          style={customStylesAskModal}
        >
          <div className="flex justify-between">
            <h1 className="font-bold text-xl">Confirmation</h1>
            <button
              onClick={() => setReRequestLiveDemo({ display: false, ID: 0 })}
            >
              <img src="/images/Close.svg" alt="close" className="h-8" />
            </button>
          </div>
          <p className="text-medium text-md text-neutral-70 my-[1.5em]">
            The demo for this module has already been completed. Are you sure
            you want to proceed with another demonstration?
          </p>
          <div className="flex justify-end">
            <div className="flex gap-2">
              <Button
                size="sm"
                type="button"
                className="font-semibold rounded-lg"
                onClick={() => {
                  setReRequestLiveDemo({ display: false, ID: 0 });
                }}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  setReRequestLiveDemo({ display: false, ID: 0 });
                  handleReRequestLiveDemo();
                }}
                type="button"
                size="sm"
                color="deep_purple_400_red_500"
                className="!bg-gradient font-semibold rounded-lg"
              >
                Yes
              </Button>
            </div>
          </div>
        </ModalR>
      )}
      {formSuccessModal?.display && (
        <AfterSubmitionModal
          customStyles={customStylesRefer}
          showModal={formSuccessModal}
          setshowModal={(e) => setFormSuccessModal(e)}
        />
      )}
      {bookFormModal?.display && (
        <Modal
          customStyles={customStylesRefer}
          showModal={bookFormModal}
          setshowModal={(e) => setbookFormModal(e)}
          handleSuccessModal={(e) => handleSuccessModal(e)}
        />
      )}
      <div className="bg-white-A700_01 space-y-[110px] relative overflow-x-hidden">
        <BrowserRouter>
          <ConditionalRoutes />
        </BrowserRouter>
      </div>
    </>
  );
}
