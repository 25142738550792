import React from "react";
import Select from "react-select";
import { useField } from "formik";

interface DropdownProps {
    label?: string;
    required?: boolean;
    options: { value: string; label: string }[]; // react-select options
    name: string;
    placeHolder: string;
    backgroundColor?: string;
    isMulti?: boolean; // To enable multi-selection
    labelClassNames?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
    label,
    options,
    name,
    placeHolder,
    required,
    backgroundColor,
    isMulti = false,
    labelClassNames
}) => {
    const [field, meta, helpers] = useField(name); // Formik's useField hook
    const { setValue } = helpers;

    return (
        <div className="flex flex-col gap-2">
            {!!label && (
                <label className={`block text-gray-700 ${!!labelClassNames ? labelClassNames : ""}`}>
                    {label} {!!required && <span className="text-red-700">*</span>}
                </label>
            )}
            <div
                style={{ backgroundColor: backgroundColor || "white" }}
                className="w-[100%] border border-solid border-black-900 rounded-2xl py-1 px-2"
            >
                <Select
                    options={options}
                    isMulti={isMulti} // Enable multi-selection
                    onChange={(selectedOptions: any) =>
                        setValue(
                            isMulti
                                ? selectedOptions?.map((option) => option.value) // Array of selected values
                                : selectedOptions?.value || "" // Single value
                        )
                    }
                    placeholder={placeHolder}
                    styles={{
                        control: (base) => ({
                            ...base,
                            backgroundColor: backgroundColor || "white",
                            borderRadius: "1rem",
                            height: isMulti ? "auto" : "40px",
                            border: "0px"
                        }),
                    }}
                    value={
                        isMulti
                            ? options.filter((option) => field?.value?.includes(option.value)) // Sync multi-select values
                            : options.find((option) => option.value === field.value) // Sync single value
                    }
                    isClearable // Optional: Allows clearing the selection
                />
                {meta.touched && meta.error && (
                    <div className="text-red-600 text-sm mt-1">{meta.error}</div>
                )}
            </div>
        </div>
    );
};

export default Dropdown;
