import axios from "axios";
import { ErrorMessage, Field } from "formik";
import React, { useCallback } from "react";
import _ from "lodash";

const TextArea: ({
    label,
    required,
    backgroundColor,
    name,
    placeHolder,
    rows,
    cols,
    mapField,
    mapName,
    labelClassNames,
}: {
    label?: string;
    required?: boolean;
    backgroundColor?: string;
    name: string;
    placeHolder?: string;
    rows?: number;
    cols?: number;
    mapField?: boolean;
    mapName?: string;
    labelClassNames?: string;
}) => React.JSX.Element = ({
    label,
    required,
    backgroundColor,
    name,
    placeHolder,
    rows = 4,
    cols = 50,
    mapField,
    mapName,
    labelClassNames,
}) => {
    const updateLocationFromAddress = async (enteredAddress, mapName, form) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    enteredAddress
                )}&key=${process.env.REACT_APP_GOOGLE_API_KEY!}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            );

            if (response?.data?.results?.length > 0) {
                const location = response?.data?.results?.[0]?.geometry?.location;
                const formattedAddress = response?.data?.results?.[0]?.formatted_address;
                form.setFieldValue(mapName, { lat: location.lat, lng: location.lng, address: formattedAddress });
            }
        } catch (error) {
            // Handle error appropriately
        }
    };

    const debouncedUpdateLocation = useCallback(
        _.debounce((item, mapName, form) => {
            updateLocationFromAddress(item, mapName, form);
        }, 500),
        [mapName, updateLocationFromAddress]
    );

    const handleChange = (event, form) => {
        const item = event.target.value;

        if (mapField && !!item) {
            debouncedUpdateLocation(item, mapName, form);
        }
        form.setFieldValue(name, item);
    };

    return (
        <div className="flex flex-col gap-2">
            {!!label && (
                <label
                    htmlFor={name}
                    style={{ color: "white" }}
                    className={`block text-white text-sm sm:text-base md:text-lg ${labelClassNames || ""}` }
                >
                    {label} {!!required && <span className="text-red-500">*</span>}
                </label>
            )}
            <div
                style={{ backgroundColor: backgroundColor || "white" }}
                className="w-full border border-solid border-black rounded-2xl py-1 px-2"
            >
                <Field name={name}>
                    {({ field, form }) => (
                        <textarea
                            id={name}
                            className="rounded-2xl w-full py-2 px-2 text-gray-800 text-sm sm:text-base md:text-lg"
                            placeholder={placeHolder || ""}
                            rows={rows}
                            cols={cols}
                            style={{ backgroundColor: backgroundColor || "white" }}
                            required={required}
                            value={field?.value?.address || field.value}
                            onChange={(event) => handleChange(event, form)}
                        />
                    )}
                </Field>
            </div>
            <ErrorMessage name={name} component="div" className="text-red-500 text-sm" />
        </div>
    );
};

export default TextArea;
